body {
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: rgb(1, 19, 31);
  height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}