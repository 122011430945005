.main-content {
    width: 100vw;
    object-fit: cover;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    background-color: rgb(175, 175, 175);
    justify-content: center;
    align-items: center;
    overflow-y: auto;   /* Enable vertical scrolling when content overflows */
}

.titlePage{
    background-color: rgb(200, 200, 200);
    border-radius: 10px;
    width: 80%;
    top: 0;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.countdown{
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}
.number{
    background-color: rgb(200, 200, 200);
    border-radius: 20px;
    width: 15%;
    margin-left: 2%;
    font-size: x-large;
    outline: 2px solid gray;
    padding-bottom: 20px;
}
.number h1{
    font-size: xxx-large;
}

img{
    width: 25%;
    border-radius: 20px;
}

.imageContainer{
    padding: 20px;
    border-radius: 20px;
    background-color: lightgray;
    margin: 2.5%;
    width: 91%;
    display: flex;
    justify-content: flex-start;
    outline: 2px solid gray;
}

.imageContainer .textContent{
    display: flex;
    justify-content: center;
    align-items: flex;
    flex-direction: column;
    width: 100%;
}

.textContent h1{
    font-size: 3vw;
}

.textContent h3{
    font-size: 2.5vw;
    font-weight: normal;
}