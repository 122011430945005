.main-content {
    width: 100vw;
    object-fit: cover;
    display: block;
    height: 100vh;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(142, 142, 142, 0.326);
    height: 100vh;
    width: 100vw;
}

.panel {
    background: linear-gradient(to bottom, rgb(230, 230, 230), rgb(180, 180, 180));
    height: 75vh;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    display: flex;
}

.card {
    width: 21%;
    border-radius: 10px;
    /*Rounded edge*/
    padding: .1vw;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
    /* Optional shadow for depth */
    margin: 2%;
    /* Space between the left component and main container*/
    background-color: white;
    line-height: 1;
    font-size: 1vw;
}

.edu {
    width: 28%;
}

.extracurricular {
    height: 175vh
}

.cardIcon {
    width: 40%;
    border-radius: 50%;
    aspect-ratio: 1;
    object-fit: cover;
    border: '2px solid black';
    /* Border for the left component */
    box-shadow: '0 0 15px rgba(0, 0, 0, 0.75)';
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Optional shadow for depth */
}

* {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For IE and Edge */
}

*::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.cardText {
    line-height: 1;
    font-size: 1.5vw;
    padding-left: 0;
}

.cardText p {
    margin: 2.25vh;
}