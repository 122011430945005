/* TopBar.css */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 7.5vh;
    background-color: #474747;
    color: #fff;
}

.links {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: red;
}

.option {
    width: 25%;
    background: none;
    border: none;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    font-size: larger;
    color: white;
}

.option:active .option:focus {
    text-decoration: underline;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-bar input {
    padding: 5px;
    margin-right: 10px;
}

.search-bar button {
    padding: 5px 10px;
}

.user-actions button {
    padding: 5px 10px;
}